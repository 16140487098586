import React from "react"
import styled from "styled-components"

import { MainWrapper, Layout } from "components"

const PageWrap = styled.div`
  color: var(--zombie);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  h3 {
    margin: 20px 0 10px 0;
    color: var(--black);
    font-family: "Oswald", sans-serif;
  }

  h1 {
    color: var(--black);
    font-family: "Oswald", sans-serif;
  }
`

export default function Terms() {
  return (
    <Layout>
      <div style={{ background: "var(--whiteChalk)" }}>
        <MainWrapper>
          <section style={{ padding: "150px 0" }}>
            <PageWrap>
              <div className="row">
                <div className="privacy-content">
                  <div className="text-center">
                    <h1 style={{ marginBottom: "10px" }}>
                      Our Terms & Conditions
                    </h1>
                    <div className="d-flex justify-content-center">
                      <p className="sans p-update">
                        Last updated: 04 January, 2022
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>

                  <p className="sans p-details">
                    Please read these Terms of Use (“Terms”, “Terms of Use”)
                    carefully before using the <b> Neighbarista</b> website (the
                    “Service”) operated by <b> Neighbarista </b> (“us”, “we”, or
                    “our”). <br />
                    <br />
                    Your access to and use of the Service is conditioned on your
                    acceptance of and compliance with these Terms. These Terms
                    apply to all visitors, users and others who access or use
                    the Service.
                    <br />
                    <br />
                    By accessing or using the Service you agree to be bound by
                    these Terms. If you disagree with any part of the terms then
                    you may not access the Service.
                  </p>
                  <div className="p-content">
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Accounts</h3>
                    </div>
                    <p>
                      When you create an account with us, you must provide us
                      information that is accurate, complete, and current at all
                      times. Failure to do so constitutes a breach of the Terms,
                      which may result in immediate termination of your account
                      on our Service. <br /> <br />
                      You are responsible for safeguarding the password that you
                      use to access the Service and for any activities or
                      actions under your password, whether your password is with
                      our Service or a third-party service.
                      <br /> <br />
                      You agree not to disclose your password to any third
                      party. You must notify us immediately upon becoming aware
                      of any breach of security or unauthorized use of your
                      account.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Intellectual Property</h3>
                    </div>
                    <p>
                      The Service and its original content, features and
                      functionality are and will remain the exclusive property
                      of <b> Neighbarista </b>
                      and its licensors.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Links To Other Web Sites</h3>
                    </div>
                    <p>
                      Our Service may contain links to third-party web sites or
                      services that are not owned or controlled by{" "}
                      <b> Neighbarista </b>. <br />
                      <br />
                      <b>Neighbarista</b> has no control over, and assumes no
                      responsibility for, the content, privacy policies, or
                      practices of any third party web sites or services. You
                      further acknowledge and agree that <b> Neighbarista </b>{" "}
                      shall not be responsible or liable, directly or
                      indirectly, for any damage or loss caused or alleged to be
                      caused by or in connection with use of or reliance on any
                      such content, goods or services available on or through
                      any such web sites or services. <br />
                      <br /> We strongly advise you to read the terms and
                      conditions and privacy policies of any third-party web
                      sites or services that you visit.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Termination</h3>
                    </div>
                    <p>
                      We may terminate or suspend access to our Service
                      immediately, without prior notice or liability, for any
                      reason whatsoever, including without limitation if you
                      breach the Terms.
                      <br />
                      <br />
                      All provisions of the Terms which by their nature should
                      survive termination shall survive termination, including,
                      without limitation, ownership provisions, warranty
                      disclaimers, indemnity and limitations of liability.
                      <br />
                      <br />
                      We may terminate or suspend your account immediately,
                      without prior notice or liability, for any reason
                      whatsoever, including without limitation if you breach the
                      Terms.
                      <br />
                      <br />
                      Upon termination, your right to use the Service will
                      immediately cease. If you wish to terminate your account,
                      you may simply discontinue using the Service.
                      <br />
                      <br />
                      All provisions of the Terms which by their nature should
                      survive termination shall survive termination, including,
                      without limitation, ownership provisions, warranty
                      disclaimers, indemnity and limitations of liability.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Disclaimer</h3>
                    </div>
                    <p>
                      Your use of the Service is at your sole risk. The Service
                      is provided on an “AS IS” and “AS AVAILABLE” basis. The
                      Service is provided without warranties of any kind,
                      whether express or implied, including, but not limited to,
                      implied warranties of merchantability, fitness for a
                      particular purpose, non-infringement or course of
                      performance.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Governing Law</h3>
                    </div>
                    <p>
                      These Terms shall be governed and construed in accordance
                      with the laws of United States without regard to its
                      conflict of law provisions. <br />
                      <br /> Our failure to enforce any right or provision of
                      these Terms will not be considered a waiver of those
                      rights. If any provision of these Terms is held to be
                      invalid or unenforceable by a court, the remaining
                      provisions of these Terms will remain in effect. These
                      Terms constitute the entire agreement between us regarding
                      our Service, and supersede and replace any prior
                      agreements we might have between us regarding the Service.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Changes</h3>
                    </div>
                    <p>
                      We reserve the right, at our sole discretion, to modify or
                      replace these Terms at any time. If a revision is material
                      we will try to provide at least 30 days notice prior to
                      any new terms taking effect. What constitutes a material
                      change will be determined at our sole discretion.
                      <br />
                      <br />
                      By continuing to access or use our Service after those
                      revisions become effective, you agree to be bound by the
                      revised terms. If you do not agree to the new terms,
                      please stop using the Service.
                    </p>
                  </div>
                </div>
              </div>
            </PageWrap>
          </section>
        </MainWrapper>
      </div>
    </Layout>
  )
}
